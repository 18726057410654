// http://ionicframework.com/docs/theming/
@import '~@ionic/angular/css/core.css';
@import '~@ionic/angular/css/normalize.css';
@import '~@ionic/angular/css/structure.css';
@import '~@ionic/angular/css/typography.css';

@import '~@ionic/angular/css/padding.css';
@import '~@ionic/angular/css/float-elements.css';
@import '~@ionic/angular/css/text-alignment.css';
@import '~@ionic/angular/css/text-transformation.css';
@import '~@ionic/angular/css/flex-utils.css';

@import './assets/icon-moon/style.css';
@import './assets/fonts/all.min.css';


@import url('https://fonts.googleapis.com/css2?family=Assistant:wght@300;400;500;600;700;800&display=swap');

ion-app {
    // margin-top: env(safe-area-inset-top);
    // margin-bottom: env(safe-area-inset-bottom);
    margin-bottom: 7px;
}

ion-content{
    // --padding-top: 55px;
    // &.padding-0{
    //     --padding-top: 0;
    // }
    
}
.conteiner-android{
    padding-top: 55px;
}

.ios-padding-top{
    padding-top: 55px;
    &.x{
        padding-top: 95px;
    }
}

.invalid-feedback {
    color: red;
    font-size: 0.7rem;
}

.d-flex {
    display: flex !important;
}

.justify-content-center {
    justify-content: center !important;
}

.align-items-center {
    align-items: center !important;
}

.flex-column {
    flex-direction: column !important;
}

.flex-row {
    flex-direction: row !important;
}
.text-center {
    text-align: center;
}
.m-auto {
    margin: auto;
}
.ml-auto {
    margin-left: auto;
}
.mr-auto {
    margin-right: auto;
}
.mt-auto {
    margin-top: auto;
}
.mb-auto {
    margin-bottom: auto;
}
.no-backdrop {
    background: none;
}
.icon-arrow-left {
    font-size: 10px;
    margin-right: auto;
}

#myInput {
    width: 100%;
    // border: 0;
    border-radius: 0;
    background: transparent;
    resize: none;
}