@font-face {
  font-family: 'icomoon';
  src:  url('fonts/icomoon.eot?6zlrpw');
  src:  url('fonts/icomoon.eot?6zlrpw#iefix') format('embedded-opentype'),
    url('fonts/icomoon.ttf?6zlrpw') format('truetype'),
    url('fonts/icomoon.woff?6zlrpw') format('woff'),
    url('fonts/icomoon.svg?6zlrpw#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-send-message:before {
  content: "\e91c";
}
.icon-user:before {
  content: "\e917";
}
.icon-files-full:before {
  content: "\e918";
}
.icon-arrow-left:before {
  content: "\e915";
}
.icon-drop-down:before {
  content: "\e916";
}
.icon-description:before {
  content: "\e914";
}
.icon-time:before {
  content: "\e910";
}
.icon-users-2:before {
  content: "\e911";
}
.icon-physical-meeting:before {
  content: "\e912";
}
.icon-location:before {
  content: "\e913";
}
.icon-close:before {
  content: "\e91b";
}
.icon-plus1:before {
  content: "\e919";
}
.icon-emoji:before {
  content: "\e91a";
}
.icon-edit-pencil:before {
  content: "\e90f";
}
.icon-chevron-left:before {
  content: "\e90d";
}
.icon-chevron-right:before {
  content: "\e90e";
}
.icon-chat:before {
  content: "\e900";
}
.icon-plus:before {
  content: "\e906";
}
.icon-users:before {
  content: "\e907";
}
.icon-back-arrow:before {
  content: "\e908";
}
.icon-calendar:before {
  content: "\e909";
}
.icon-checkbox-outline:before {
  content: "\e90a";
}
.icon-home:before {
  content: "\e90b";
}
.icon-square-outline:before {
  content: "\e90c";
}
.icon-employers:before {
  content: "\e905";
}
.icon-events:before {
  content: "\e901";
}
.icon-files:before {
  content: "\e902";
}
.icon-processes:before {
  content: "\e903";
}
.icon-tasks:before {
  content: "\e904";
}
.icon-phone:before {
  content: "\e942";
}
.icon-circle-up:before {
  content: "\ea41";
}
